import axios from "axios"
import { useEffect, useState } from "react"
import FormButton from "../../components/FormButton"
import { getName, getToken } from "../../user/User"
import Select from "react-select"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const ProspectDetailView = ({ id, name, setName, birthday, setBirthday, phone, setPhone, mobile, setMobile, emailAddress, setEmailAddress, street, setStreet,
    postalCode, setPostalCode, city, setCity, meetingTime, setMeetingTime, meetingLocation, setMeetingLocation, wantToSell, setWantToSell,
    wantToSellWithinAYear, setWantToSellWithinAYear, notes, setNotes, callAt, setCallAt, campaignId, setCampaignId, returnTo, canSendMailToFirm, canSendMailToProspect }) => {
    
    const navigate = useNavigate()

    const [sendMailToFirm, setSendMailToFirm] = useState(false)
    const [sendMailToProspect, setSendMailToProspect] = useState(false)
    async function trySetSendMailToFirm(state) {
        if(canSendMailToFirm) {
            setSendMailToFirm(state)
        } else if(state) {
            toast.warn("The email configuration is not set up for this campaign.")
        }
    }
    async function trySetSendMailToProspect(state) {
        if(canSendMailToProspect && emailAddress != "") {
            setSendMailToProspect(state)
        } else if(state) {
            if(emailAddress == "") {
                toast.warn("The prospect has no email address.")
            } else {
                toast.warn("The email configuration is not set up for this campaign.")
            }
        }
    }
	useEffect(() => {
        if(canSendMailToFirm) {
            setSendMailToFirm(canSendMailToFirm)
        }
        if(canSendMailToProspect) {
            setSendMailToProspect(canSendMailToProspect)
        }
    }, [canSendMailToFirm, canSendMailToProspect])

    const [campaignSelect, setCampaignSelect] = useState(<></>)
	useEffect(() => {
        if(campaignId == 0) {
            return
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/campaigns", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            const options = []
            res.data.forEach((campaign, _) => {
                options.push({
                    value: campaign.id,
                    label: campaign.name
                })
            })

            setCampaignSelect(<Select options={options} onChange={(choice) => setCampaignId(choice.value)} value={options.filter(option => option.value == campaignId)} />)
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load campaigns")
        })
    }, [campaignId])
    
    const [meetingLocationTeams, setMeetingLocationTeams] = useState(<></>)
    const [meetingLocationHouse, setMeetingLocationHouse] = useState(<></>)
    useEffect(() => {
        if(meetingLocation == "teams") {
            setMeetingLocationTeams(<input className="form-check-input" type="radio" name="meetingLocation" id="teams" defaultChecked/>)
            setMeetingLocationHouse(<input className="form-check-input" type="radio" name="meetingLocation" id="house" />)
        } else if(meetingLocation == "house") {
            setMeetingLocationTeams(<input className="form-check-input" type="radio" name="meetingLocation" id="teams" />)
            setMeetingLocationHouse(<input className="form-check-input" type="radio" name="meetingLocation" id="house" defaultChecked/>)
        }
    }, [meetingLocation])
    function onMeetingLocationChanged(event) {
        setMeetingLocation(event.target.id)
    }
    
    const deleteProspect = async e => {
        e.preventDefault()
        if (confirm('Do you really want to delete this prospect?')) {
            axios.delete(process.env.REACT_APP_API_URL + "/api/prospects/" + id, { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                toast.success("Deleted prospect " + name + " #" + id + ".")
                if(returnTo == "reload") {
                    window.location.reload()
                } else {
                    navigate(returnTo)
                }
            }).catch(error => {
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Invalid request.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Unable to find prospect #" + id + ".")
                        return
                    }
                }
                toast.error("Internal error.")
            })
        }
    }
    
    const onSave = async e => {
        e.preventDefault()
        saveProspect(null)
    }
    const onBooked = async e => {
        e.preventDefault()
        saveProspect("Booked")
    }
    const onCallback = async e => {
        e.preventDefault()
        saveProspect("Callback")
    }
    const onBusy = async e => {
        e.preventDefault()
        saveProspect("Busy")
    }
    const onNotInterested = async e => {
        e.preventDefault()
        saveProspect("Not interested")
    }
    
    async function saveProspect(action) {
        var birthdayTimestamp = birthday === "" ? 0 : Math.floor(new Date(birthday).getTime() / 1000)
        if(birthdayTimestamp < 0) {
            birthdayTimestamp = 0
        }
        var meetingTimeTimestamp = meetingTime === "" ? 0 : Math.floor(new Date(meetingTime).getTime() / 1000)
        if(meetingTimeTimestamp < 0) {
            meetingTimeTimestamp = 0
        }
        var callAtTimestamp = callAt === "" ? 0 : Math.floor(new Date(callAt).getTime() / 1000)
        if(callAtTimestamp < 0) {
            callAtTimestamp = 0
        }
    
        if(action == "Callback") {
            if(callAtTimestamp == 0) {
                toast.warn("'Call at the following time' must be set.")
                return
            }
        } else if(action == "Booked") {
            if(name == "" || (phone == "" && mobile == "") || emailAddress == "" || street == "" || postalCode == "" || city == "" || 
                birthdayTimestamp == 0 || meetingTimeTimestamp == 0) {
                toast.warn("Name, phone, mobile, email address, street, postal code, city, birthday and meeting time must be set.")
                return
            }
        }
    
        axios.put(process.env.REACT_APP_API_URL + "/api/prospects/" + id, JSON.stringify({
            "campaign_id": parseInt(campaignId),
            "name": name,
            "phone": phone,
            "mobile": mobile,
            "email_address": emailAddress,
            "street": street,
            "postal_code": postalCode,
            "city": city,
            "birthday": birthdayTimestamp,
            "meeting_time": meetingTimeTimestamp,
            "meeting_location": meetingLocation,
            "want_to_sell": wantToSell,
            "want_to_sell_within_a_year": wantToSellWithinAYear
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            if(action == null) {
                toast.success("Saved prospect " + name + " (#" + id + ").")
                return
            }
            axios.post(process.env.REACT_APP_API_URL + "/api/prospects/action/" + id, JSON.stringify({
                "action": action,
                "notes": notes,
                "call_at": callAtTimestamp,
                "send_mail_to_firm": sendMailToFirm,
                "send_mail_to_prospect": sendMailToProspect,
                "username": getName()
            }), { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                window.location.reload()
                toast.success("Saved prospect " + name + " (#" + id + ") as '" + action + "'.")
            }).catch(error => {
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Invalid request.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Unable to find prospect #" + id + ".")
                        return
                    } else if(statusCode === 409) {
                        toast.error("There is an prospect with the same phone and mobile number.")
                        return
                    }
                }
                toast.error("Internal error")
            })
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find prospect #" + id + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is an prospect with the same phone and mobile number.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    return (
        <>
            <form className="form-horizontal">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-3 col-12">
                                <b>Campaign</b>
                                {campaignSelect}
                                <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <FormButton label="Name" id="name" value={name} setValue={setName} icon="fa-solid fa-input-text" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                            <div className="col-lg-6 col-12">
                                <FormButton label="Birthday" id="birthday" type="date" value={birthday} setValue={setBirthday} icon="fa-solid fa-cake-candles" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <FormButton label="Phone" id="phone" type="tel" value={phone} setValue={setPhone} icon="fa-solid fa-phone" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                            <div className="col-lg-4 col-12">
                                <FormButton label="Mobile" id="mobile" type="tel" value={mobile} setValue={setMobile} icon="fa-solid fa-mobile" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                            <div className="col-lg-4 col-12">
                                <FormButton label="Email address" id="emailAddress" type="email" value={emailAddress} setValue={setEmailAddress} icon="fa-solid fa-envelope" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <FormButton label="Street" id="street" value={street} setValue={setStreet} icon="fa-solid fa-road" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                            <div className="col-lg-4 col-12">
                                <FormButton label="Postal code" id="postalCode" value={postalCode} setValue={setPostalCode} icon="fa-solid fa-mailbox" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                            <div className="col-lg-4 col-12">
                                <FormButton label="City" id="city" value={city} setValue={setCity} icon="fa-solid fa-city" labelSize="col-lg-3" size="col-lg-7" />
                            </div>
                        </div>

                        {phone != "" ? <a className="btn btn-success" href={"tel:" + phone}><i className="fa-solid fa-phone"></i> Call phone</a> : ""}
                        {mobile != "" ? <a className="btn btn-success" href={"tel:" + mobile}><i className="fa-solid fa-mobile"></i> Call mobile</a> : ""}
                        <br /><br />

                        <p><b>Meeting</b></p>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <FormButton label="Time" id="meetingTime" type="datetime-local" value={meetingTime} setValue={setMeetingTime} icon="fa-solid fa-clock" labelSize="col-lg-3" size="col-lg-6" />
                            </div>
                            <div className="col-lg-6 col-12" onChange={onMeetingLocationChanged}>
                                <p><b>Location</b></p>
                                <div className="form-check">
                                    {meetingLocationTeams}
                                    <label className="form-check-label" htmlFor="teams">
                                        Teams
                                    </label>
                                </div>
                                <div className="form-check">
                                    {meetingLocationHouse}
                                    <label className="form-check-label" htmlFor="house">
                                        House
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="icheck-primary">
                            <input type="checkbox" id="wantToSell" checked={wantToSell ? "checked" : ""} onChange={e => setWantToSell(e.target.checked)} />
                            <label htmlFor="wantToSell">
                                Want to sell
                            </label>
                        </div>
                        <div className="icheck-primary">
                            <input type="checkbox" id="wantToSellWithinAYear" checked={wantToSellWithinAYear ? "checked" : ""} onChange={e => setWantToSellWithinAYear(e.target.checked)} />
                            <label htmlFor="wantToSellWithinAYear">
                                Want to sell within a year
                            </label>
                        </div>
                    </div>

                    <div className="card-footer">
                        <FormButton label="Notes" id="notes" type="textarea" value={notes} setValue={setNotes} icon="fa-solid fa-notes" />

                        <FormButton label="Call at the following time" id="call_at" type="datetime-local" value={callAt} setValue={setCallAt} icon="fa-solid fa-clock" />

                        <div className="icheck-primary">
                            <input type="checkbox" id="sendMailToFirm" checked={sendMailToFirm} onChange={e => trySetSendMailToFirm(e.target.checked)} />
                            <label htmlFor="sendMailToFirm">
                                Send mail to firm if booked
                            </label>
                        </div>
                        <div className="icheck-primary">
                            <input type="checkbox" id="sendMailToProspect" checked={sendMailToProspect} onChange={e => trySetSendMailToProspect(e.target.checked)} />
                            <label htmlFor="sendMailToProspect">
                                Send mail to prospect if booked
                            </label>
                        </div>

                        <button className="btn btn-primary" onClick={onSave}>Save</button> <button className="btn btn-success" onClick={onBooked}>Booked</button> <button className="btn btn-warning" onClick={onCallback}>Callback</button> <button className="btn btn-warning" onClick={onBusy}>Busy</button> <button className="btn btn-danger" onClick={onNotInterested}>Not interested</button> <button className="btn btn-danger" onClick={deleteProspect}>NIX</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default ProspectDetailView
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getId, getToken, usePermissions } from "../../user/User"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { toast } from "react-toastify"

const CallbackProspectList = () => {
    const navigate = useNavigate()
    const { permissions } = usePermissions(getId())
    const [createProspectButton, setCreateProspectButton] = useState(<></>)

    useEffect(() => {
        if(permissions.prospect !== undefined && permissions.prospect) {
            setCreateProspectButton(<button className="btn btn-primary" onClick={handleCreate}>Create prospect</button>)
        }
    }, [permissions])

    const handleCreate = async e => {
        e.preventDefault(),
        navigate("/prospect/create")
    }

    const columns =  [{
        Header: "Edit",
        UrlPart: "prospect"
    }, {
        Header: "Remove callback",
        UrlPart: "removecallback"
    }, {
        Header: "Callback at",
        accessor: (row, _) => {
            if(row.call_at <= 0) {
                return ""
            }
            return new Date(row.call_at * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        }
    }, {
        Header: "Campaign",
        accessor: "campaign_name",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Phone",
        accessor: "phone"
    }, {
        Header: "Mobile",
        accessor: "mobile"
    }, {
        Header: "Email address",
        accessor: "email_address"
    }, {
        Header: "Street",
        accessor: "street"
    }, {
        Header: "Postal code",
        accessor: "postal_code"
    }, {
        Header: "City",
        accessor: "city"
    }, {
        Header: "Birthday",
        accessor: (row, _) => {
            if(row.birthday <= 0) {
                return ""
            }
            return new Date(row.birthday * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        }
    }, {
        Header: "Meeting time",
        accessor: (row, _) => {
            if(row.meeting_time <= 0) {
                return ""
            }
            return new Date(row.meeting_time * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
        }
    }, {
        Header: "Meeting location",
        accessor: (row, _) => {
            return row.meeting_location === "teams" ? "Teams" : "House"
        },
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Want to sell",
        accessor: (row, _) => {
            return row.want_to_sell ? "Yes" : "No"
        },
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Want to sell within a year",
        accessor: (row, _) => {
            return row.want_to_sell_within_a_year ? "Yes" : "No"
        },
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }]

    const [prospects, setProspects] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/prospects/callback", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setProspects(res.data)
        }).catch(_ => {
            toast.error("Unable to load prospects!")
        })
    }, [])

    return (
        <div>
            {createProspectButton}

            <BasicTableComponent columns={columns} data={prospects} />
        </div>
    )
}

export default CallbackProspectList
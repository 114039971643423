import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions, useUser } from "../../user/User"
import FormButton from "../../components/FormButton"
import { toast } from "react-toastify"
import { useEffect } from "react"
import Permissions from "./Permissions"

const UserEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { email, name, setName } = useUser(id)
    const { permissions: userPermissions, setPermissions: setUserPermissions } = usePermissions(id)
    const { permissions } = usePermissions(getId())

    useEffect(() => {
        if(permissions.user !== undefined && !permissions.user) {
            toast.error("You don't have permission to edit users!")
            navigate("/")
        }
    }, [permissions])

    async function saveUser() {
        axios.put(process.env.REACT_APP_API_URL + "/api/users/" + id, JSON.stringify({
            "email": email,
            "name": name
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            savePermissions()
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find user with email address " + email + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is an user with the same email address.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    async function savePermissions() {
        axios.put(process.env.REACT_APP_API_URL + "/api/permissions/" + id, JSON.stringify({
            "email": email,
            "permissions": userPermissions
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Saved user " + name + ".")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find user " + email + " (#" + id + ").")
                    return
                }
            }
            toast.error("Internal error.")
        })
    }

    async function deleteUser() {
        axios.delete(process.env.REACT_APP_API_URL + "/api/users/" + id, {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Deleted user " + name + ".")
            navigate("/user")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find user #" + id + ".")
                    return
                }
            }
            toast.error("Internal error.")
        })
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/user")
    }

    const handleSubmit = async e => {
        e.preventDefault()
        saveUser()
    }

    const handleDelete = async e => {
        e.preventDefault()
        deleteUser()
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Email address" id="email" required="required" value={email} disabled="disabled" icon="fa-solid fa-envelope" />
                            <FormButton label="Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-input-text" />
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="col-12">
                            <Permissions data={userPermissions} setData={setUserPermissions} />
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Save user</button> <button className="btn btn-danger" onClick={handleDelete}>Delete user</button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default UserEdit
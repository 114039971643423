import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions } from "../../user/User"
import FormButton from "../../components/FormButton"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"

const CampaignEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    const [name, setName] = useState("")
    const [prospectEmailSubject, setProspectEmailSubject] = useState("")
    const [prospectEmailBody, setProspectEmailBody] = useState("")
    const [firmEmailAddress, setFirmEmailAddress] = useState("")
    const [firmEmailSubject, setFirmEmailSubject] = useState("")
    const [firmEmailBody, setFirmEmailBody] = useState("")

    const [statusMessage, setStatusMessage] = useState("")
    const [output, setOutput] = useState([])
    let fileReader = new FileReader

    useEffect(() => {
        if(id !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/campaigns/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                setName(res.data.name)
                setProspectEmailSubject(res.data.prospect_email_subject)
                setProspectEmailBody(res.data.prospect_email_body)
                setFirmEmailAddress(res.data.firm_email_address)
                setFirmEmailSubject(res.data.firm_email_subject)
                setFirmEmailBody(res.data.firm_email_body)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.campaign !== undefined && !permissions.campaign) {
            toast.error("You don't have permission to edit campaigns!")
            navigate("/")
        }
    }, [permissions])

    async function saveCampaign() {
        axios.put(process.env.REACT_APP_API_URL + "/api/campaigns/" + id, JSON.stringify({
            "prospect_email_subject": prospectEmailSubject,
            "prospect_email_body": prospectEmailBody,
            "firm_email_address": firmEmailAddress,
            "firm_email_subject": firmEmailSubject,
            "firm_email_body": firmEmailBody
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Saved campaign " + name + ".")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find campaign #" + id + ".")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a campaign with the same name.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    async function deleteCampaign() {
        axios.delete(process.env.REACT_APP_API_URL + "/api/campaigns/" + id, {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Deleted campaign " + name + ".")
            navigate("/campaign")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find campaign #" + id + ".")
                    return
                }
            }
            toast.error("Internal error.")
        })
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/campaign")
    }

    const handleSubmit = async e => {
        e.preventDefault()
        saveCampaign()
    }

    const handleDelete = async e => {
        e.preventDefault()
        deleteCampaign()
    }

    function uploadFile(event) {
        let file = event.target.files[0]
        if (file) {
            fileReader.onloadend = handleFileRead
            fileReader.readAsText(file)
        }
    }

    const handleFileRead = async e => {
        const content = fileReader.result
        if(content == null || content == "") {
            toast.warn("The file is empty")
            return
        }
        let lines = content.split(/\r?\n|\r|\n/g)
        let lineNumber = 0
        let imported = []
        let result = []
        lines.forEach(line => {
            lineNumber++
            if(line === "") {
                imported.push(lineNumber)
                setStatusMessage("Imported " + imported.length + "/" + lines.length + " prospects.")
                result.push("Unable to import line " + lineNumber + ": Row is empty")
                return
            }
            let lineParts = line.split(";")
            let name = lineParts[0]
            let phone = lineParts[1]
            let mobile = lineParts[2]
            let emailAddress = lineParts[3]
            let street = lineParts[4]
            let postalCode = lineParts[5]
            let city = lineParts[6]
            let birthday = lineParts[7]
            if((phone == undefined || phone == "") && (mobile == undefined || mobile == "")) {
                imported.push(lineNumber)
                setStatusMessage("Imported " + imported.length + "/" + lines.length + " prospects.")
                result.push("Unable to import line " + lineNumber + ": Phone or mobile number is missing")
                return
            }
            const birthdayDate = new Date(birthday.substring(0,4) + "-" + birthday.substring(4,6) + "-" + birthday.substring(6))
            let currentLineNumber = lineNumber
            axios.post(process.env.REACT_APP_API_URL + "/api/prospects", JSON.stringify({
                "campaign_id": parseInt(id),
                "name": name,
                "phone": phone,
                "mobile": mobile,
                "email_address": emailAddress,
                "street": street,
                "postal_code": postalCode,
                "city": city,
                "birthday": birthdayDate.getTime() / 1000,
                "meeting_time": 0,
                "meeting_location": "teams",
                "want_to_sell": false,
                "want_to_sell_within_a_year": false
            }), { headers: {
                "Authorization": "Bearer " + getToken()
            }}).then(_ => {
                imported.push(currentLineNumber)
                setStatusMessage("Imported " + imported.length + "/" + lines.length + " prospects.")
            }).catch(error => {
                if (error.response) {
                    let code = error.response.status
                    if(code == 409) {
                        result.push("Unable to import line " + currentLineNumber + ": Phone or mobile number is already used")
                    } else {
                        result.push("Unable to import line " + currentLineNumber + ": Create failed with status code " + code)
                    }
                } else {
                    result.push("Unable to import line " + currentLineNumber + ": Create failed with an internal error")
                }
                setOutput([...result])
                imported.push(currentLineNumber)
                setStatusMessage("Imported " + lineNumber + "/" + lines.length + " prospects.")
            })
        })
        setOutput(result)
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton disabled={true} label="Name" id="name" required="required" value={name} icon="fa-solid fa-input-text" />
                        </div>
                        <div className="col-12">
                            <p><b>Mail for prospect when booked</b></p>
                            <FormButton label="Subject" id="prospectMailSubject" value={prospectEmailSubject} setValue={setProspectEmailSubject} icon="fa-solid fa-input-text" />
                            <FormButton type="textarea" label="Body" id="prospectMailBody" value={prospectEmailBody} setValue={setProspectEmailBody} icon="fa-solid fa-input-text" size={"col-12"} />
                            <p>You can use the following placeholders: {"{{.Name}}, {{.MeetingTime}}, {{.MeetingLocation}}, {{.Street}}, {{.PostalCode}}, {{.City}}, {{.Phone}}, {{.Mobile}}, {{.EmailAddress}}, {{.Birthday}}, {{.Campaign}}, {{.WantToSell}}, {{.WantToSellWithinAYear}}, {{.AgentName}}, {{.Note}}"}</p>
                        </div>
                        <div className="col-12">
                            <p><b>Mail for firm when booked</b></p>
                            <FormButton type="email" label="Email address" id="firmEmailAddress" value={firmEmailAddress} setValue={setFirmEmailAddress} icon="fa-solid fa-envelope" />
                            <FormButton label="Subject" id="firmEmailSubject" value={firmEmailSubject} setValue={setFirmEmailSubject} icon="fa-solid fa-input-text" />
                            <FormButton type="textarea" label="Body" id="firmEmailBody" value={firmEmailBody} setValue={setFirmEmailBody} icon="fa-solid fa-input-text" size={"col-12"} />
                            <p>You can use the following placeholders: {"{{.Name}}, {{.MeetingTime}}, {{.MeetingLocation}}, {{.Street}}, {{.PostalCode}}, {{.City}}, {{.Phone}}, {{.Mobile}}, {{.EmailAddress}}, {{.Birthday}}, {{.Campaign}}, {{.WantToSell}}, {{.WantToSellWithinAYear}}, {{.AgentName}}, {{.Note}}"}</p>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Save campaign</button> <button className="btn btn-danger" onClick={handleDelete}>Delete campaign</button>
                    </div>
                </div>
            </form>

            <div className="card card-primary">
                <div className="card-body">
                    <div className="col-12">
                        <p>The order for the csv file is: Name, Phone, Mobile, Email address, Street, Postal code, City, Birthday (yyyyMMdd). The values need to be seperated by a semicolon (;).</p>

                        <div className="mb-3">
                            <label htmlFor="importFile" className="form-label">Import prospects</label>
                            <input className="form-control" type="file" id="importFile" name="importFile" accept=".csv" onChange={uploadFile} />
                        </div>
                    </div>
                </div>
                
                <div className="card-footer">
                    {statusMessage === "" ? "" : <div className="alert alert-info" role="alert">{statusMessage}</div>}
                    {output.length === 0 ? "" : <div className="alert alert-warning" role="alert">{output.map((line,i) => <p key={i}>{line}</p>)}</div>}
                </div>
            </div>
        </>
    )
}

export default CampaignEdit
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"

const CampaignCreate = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.campaign !== undefined && !permissions.campaign) {
            toast.error("You don't have permission to create campaigns!")
            navigate("/")
        }
    }, [permissions])

    const [name, setName] = useState("")
    const [prospectEmailSubject, setProspectEmailSubject] = useState("")
    const [prospectEmailBody, setProspectEmailBody] = useState("")
    const [firmEmailAddress, setFirmEmailAddress] = useState("")
    const [firmEmailSubject, setFirmEmailSubject] = useState("")
    const [firmEmailBody, setFirmEmailBody] = useState("")

    async function createCampaign() {
        axios.post(process.env.REACT_APP_API_URL + "/api/campaigns", JSON.stringify({
            "name": name,
            "prospect_email_subject": prospectEmailSubject,
            "prospect_email_body": prospectEmailBody,
            "firm_email_address": firmEmailAddress,
            "firm_email_subject": firmEmailSubject,
            "firm_email_body": firmEmailBody
        }), {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            toast.success("Created campaign " + name + " (#" + res.data.id + ").")
            navigate("/campaign/" + res.data.id)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a campaign with the same name.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createCampaign()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/campaign")
    }
    
    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-input-text" />
                        </div>
                        <div className="col-12">
                            <p><b>Mail for prospect when booked</b></p>
                            <FormButton label="Subject" id="prospectMailSubject" value={prospectEmailSubject} setValue={setProspectEmailSubject} icon="fa-solid fa-input-text" />
                            <FormButton type="textarea" label="Body" id="prospectMailBody" value={prospectEmailBody} setValue={setProspectEmailBody} icon="fa-solid fa-input-text" size={"col-12"} />
                            <p>You can use the following placeholders: {"{{.Name}}, {{.MeetingTime}}, {{.MeetingLocation}}, {{.Street}}, {{.PostalCode}}, {{.City}}, {{.Phone}}, {{.Mobile}}, {{.EmailAddress}}, {{.Birthday}}, {{.Campaign}}, {{.WantToSell}}, {{.WantToSellWithinAYear}}, {{.AgentName}}, {{.Note}}"}</p>
                        </div>
                        <div className="col-12">
                            <p><b>Mail for firm when booked</b></p>
                            <FormButton type="email" label="Email address" id="firmEmailAddress" value={firmEmailAddress} setValue={setFirmEmailAddress} icon="fa-solid fa-envelope" />
                            <FormButton label="Subject" id="firmEmailSubject" value={firmEmailSubject} setValue={setFirmEmailSubject} icon="fa-solid fa-input-text" />
                            <FormButton type="textarea" label="Body" id="firmEmailBody" value={firmEmailBody} setValue={setFirmEmailBody} icon="fa-solid fa-input-text" size={"col-12"} />
                            <p>You can use the following placeholders: {"{{.Name}}, {{.MeetingTime}}, {{.MeetingLocation}}, {{.Street}}, {{.PostalCode}}, {{.City}}, {{.Phone}}, {{.Mobile}}, {{.EmailAddress}}, {{.Birthday}}, {{.Campaign}}, {{.WantToSell}}, {{.WantToSellWithinAYear}}, {{.AgentName}}, {{.Note}}"}</p>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Create campaign</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default CampaignCreate
const ProspectActionTimeline = ({ prospectActions }) => {
    return (
        <>
            <b>Timeline</b>
            {prospectActions.map(action => 
                <div key={action.id} className={"card text-white bg-" + (action.action == "Not interested" ? "danger" : (action.action == "Callback" || action.action == "Busy" ? "warning" : action.action == "Booked" ? "success" : "info"))}>
                    <div className="card-header">{new Date(action.created * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })} - {action.action}</div>
                    <div className="card-body">
                        <p className="card-text">{action.notes}</p>
                        <p className="card-text">- {action.username}</p>
                    </div>
                </div>
            )}
        </>
    )
}
   
export default ProspectActionTimeline
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getId, getToken, usePermissions } from "../../user/User"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { toast } from "react-toastify"

const CampaignList = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.campaign !== undefined && !permissions.campaign) {
            toast.error("You don't have permission to see campaigns!")
            navigate("/")
        }
    }, [permissions])

    const handleCreate = async e => {
        e.preventDefault(),
        navigate("/campaign/create")
    }

    const columns = [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Email address",
        accessor: "firm_email_address"
    }, {
        Header: "Edit",
        UrlPart: "campaign"
    }]

    const [campaigns, setCampaigns] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/campaigns", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setCampaigns(res.data)
        }).catch(_ => {
            toast.error("Unable to load campaigns!")
        })
    }, [])

    return (
        <div>
            <button className="btn btn-primary" onClick={handleCreate}>Create campaign</button>

            <BasicTableComponent columns={columns} data={campaigns} />
        </div>
    )
}

export default CampaignList
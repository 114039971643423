import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { getId, getToken, useSettings } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import FormButton from "../../components/FormButton"

const Enable2FA = () => {
    const navigate = useNavigate()
    const [ qrCode, setQrCode ] = useState(<p>"Loading QR code..."</p>)
    const [ otp, setOTP ] = useState("")
    let { twoFactorAuthenticationEnabled } = useSettings(getId())

    useEffect(() => {
        if(twoFactorAuthenticationEnabled) {
            navigate("/settings")
            toast.warn("Two factor authentication is already enabled")
            return
        }
        
        axios.get(process.env.REACT_APP_API_URL + "/api/settings/2fa/request", { responseType: 'arraybuffer', headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            var binary = ''
            var bytes = [].slice.call(new Uint8Array(res.data))
            bytes.forEach((b) => binary += String.fromCharCode(b))
            setQrCode(<img src={'data:image/jpeg;base64,' + window.btoa(binary)}/>)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }, [twoFactorAuthenticationEnabled])

    const handleSubmit = async e => {
        axios.put(process.env.REACT_APP_API_URL + "/api/settings/2fa/enable", JSON.stringify({
            "otp": otp
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            navigate("/settings")
            toast.success("Enabled two factor authentication")
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 401) {
                    toast.warn("Invalid one time password. Please try again.")
                    return
                }
            }
            toast.error("Internal error")
        })
        e.preventDefault()
    }

    return (
        <>
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <p>
                                Scan the QR code on your mobile device to setup two factor authentication.
                            </p>
                            {qrCode}
                            <p>Once scanned, insert the one-time password from your authenticator app to finish the setup.</p>
                            <FormButton id="otp" required="required" value={otp} setValue={setOTP} icon="fas fa-hashtag" type="text" placeholder="One time password" />
                            <button type="submit" className="btn btn-success">Finish 2FA setup</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Enable2FA
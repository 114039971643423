import useBreadcrumbs from 'use-react-router-breadcrumbs'

const Header = () => {
  const routes = [
    { path: '/', props: { value: 'Home' }},
    { path: '/user', props: { value: 'User' }},
    { path: '/user/create', props: { value: 'Create user' }},
    { path: '/user/*', props: { value: 'Edit user' }},
    { path: '/settings', props: { value: 'Settings' }},
    { path: '/settings/enable2fa', props: { value: 'Enable two factor authentication' }},
    { path: '/campaign', props: { value: 'Campaign' }},
    { path: '/campaign/create', props: { value: 'Create campaign' }},
    { path: '/campaign/call/*', props: { value: 'Call campaign' }},
    { path: '/campaign/*', props: { value: 'Edit campaign' }},
    { path: '/prospect', props: { value: 'Prospect' }},
    { path: '/prospect/import', props: { value: 'Import prospect' }},
    { path: '/prospect/callback', props: { value: 'Prospect callback' }},
    { path: '/prospect/create', props: { value: 'Create prospect' }},
    { path: '/prospect/*', props: { value: 'Edit prospect' }},
    { path: '/*', props: { value: '404 Not found' }},
  ]
  const breadcrumbs = useBreadcrumbs(routes)
  const active = breadcrumbs[breadcrumbs.length - 1].value

  return (
      <section className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h2>{active}</h2>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              {breadcrumbs.map((breadcrumb) => active === breadcrumb.value ? null : <li className="breadcrumb-item" key={breadcrumb.value}><a href={breadcrumb.key}>{breadcrumb.value}</a></li>)}
              <li className="breadcrumb-item active" key={active}>{active}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  )
}
   
export default Header
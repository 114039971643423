import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { sha512 } from 'crypto-hash'
import axios from 'axios'
import { isLoggedIn } from "../user/User"
import { toast } from 'react-toastify'
import FormButton from '../components/FormButton'

const Login = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [remember, setRemember] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    if (isLoggedIn()) {
        navigate("/")
        return
    }

    async function loginUser(email, password, remember, navigate) {
        setIsLoading(true)
        var passwordHash = await sha512(password)

        axios.post(process.env.REACT_APP_API_URL + "/login", JSON.stringify({
            "email": email,
            "password": passwordHash
        })).then(res => {
            localStorage.clear()
            sessionStorage.clear()
            if(remember) {
                localStorage.setItem('token', res.data)
            } else {
                sessionStorage.setItem('token', res.data)
            }
            toast.success("Successfully logged in.")
            navigate("/")
        }).catch(error => {
            setIsLoading(false)
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 401) {
                    toast.error("Wrong password.")
                    return
                } else if(statusCode === 403) {
                    navigate("/login2fa", { state: { email: email, passwordHash: passwordHash, remember: remember } })
                    return
                }
            }
            toast.error("Internal error.")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        loginUser(email, password, remember, navigate)
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <h1>{process.env.REACT_APP_APP_NAME}</h1>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Please log in.</p>

                        <form onSubmit={handleSubmit}>
                            <FormButton id="email" required="required" value={email} setValue={setEmail} icon="fas fa-envelope" type="email" placeholder="Email address" size="mb-3" />
                            <FormButton id="password" required="required" value={password} setValue={setPassword} icon="fas fa-lock" type="password" placeholder="Password" size="mb-3" />

                            <div className="row">
                                <div className="col-12">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" onChange={e => setRemember(e.target.checked)} />
                                        <label htmlFor="remember">
                                            Remember me
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12">
                                    {
                                        isLoading ?
                                        <button type="submit" className="btn btn-primary btn-block" disabled>Logging in...</button>
                                        :
                                        <button type="submit" className="btn btn-primary btn-block">Login</button>
                                    }
                                </div>
                            </div>
                        </form>

                        <p className="mb-1">
                            <Link to="/request-password-reset">Reset password</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default Login
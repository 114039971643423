import axios from 'axios'
import jwt from 'jwt-decode'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const GetUsers = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        const abortCont = new AbortController()
        
        axios.get(process.env.REACT_APP_API_URL + "/api/users", {
            signal: abortCont.signal,
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(response => {
            return response.json()
        })
        .then(res => {
            setUsers(res.data)
        }).catch(err => {
            if(err.message === "canceled") {
                return
            }
            toast.error("Could not load user.")
        })
    
        return () => abortCont.abort()
    }, [])

    return { users }
}

export const useCurrentUser = () => {
    return useUser(getId())
}

export const useUser = (id) => {
    const [name, setName] = useState(id)
    const [email, setEmail] = useState(id)
    useEffect(() => {
        if(id !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/users/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                setName(res.data.name)
                setEmail(res.data.email)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { id, email, setEmail, name, setName }
}


export const useSettings = (id) => {
    const [twoFactorAuthenticationEnabled, setTwoFactorAuthenticationEnabled] = useState(false)
    useEffect(() => {
        if(id !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/settings/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                setTwoFactorAuthenticationEnabled(res.data.two_factor_authentication_enabled)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { twoFactorAuthenticationEnabled }
}

export function getId() {
    var id = null
    const token = getTokenData()
    if(token !== null) {
        id = token.id
    }

    return id
}

export function getEMail() {
    var email = null
    const token = getTokenData()
    if(token !== null) {
        email = token.email
    }

    return email
}

export function getName() {
    var name = null
    const token = getTokenData()
    if(token !== null) {
        name = token.name
    }

    return name
}

export function isLoggedIn() {
    return getTokenData() !== null
}

export function getTokenData() {
    var token = getToken()
    if(token) {
        try {
            var data = jwt(token)
            if(data.exp > Math.floor(Date.now() / 1000)) {
                return data
            }
        } catch(e) {
        }
    }

    return null
}

export function getToken() {
    var token = sessionStorage.getItem('token')
    if(token === null) {
        token = localStorage.getItem('token')
    }

    return token
}

export const usePermissions = (id) => {
    const [permissions, setPermissions] = useState({})
    useEffect(() => {
        const abortCont = new AbortController()
        
        axios.get(process.env.REACT_APP_API_URL + "/api/permissions" + (id !== null && id !== "" ? "/" + id : ""), {
            signal: abortCont.signal,
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
            setPermissions(res.data.permissions)
        }).catch(err => {
            if(err.message === "canceled") {
                return
            }
            toast.error("Internal error")
        })
    
        return () => abortCont.abort()
    }, [id])

    return { permissions, setPermissions }
}
import axios from "axios"
import { sha512 } from "crypto-hash"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"
import Permissions from './Permissions'

const UserCreate = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.user !== undefined && !permissions.user) {
            toast.error("You don't have permission to create users!")
            navigate("/")
        }
    }, [permissions])

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [id, setId] = useState(0)
    const [password, setPassword] = useState("")
    const { permissions: userPermissions, setPermissions: setUserPermissions } = usePermissions(id)

    async function createUser() {
        var passwordHash = await sha512(password)
        axios.post(process.env.REACT_APP_API_URL + "/api/users", JSON.stringify({
            "email": email,
            "name": name,
            "password": passwordHash
        }), {
        headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            setId(res.data.id)
            savePermissions(res.data.id)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is an user with the same email address.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    async function savePermissions(userId) {
        axios.put(process.env.REACT_APP_API_URL + "/api/permissions/" + userId, JSON.stringify({
            "user_id": userId,
            "permissions": userPermissions
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Created user " + name + " (#" + userId + ").")
            navigate("/user/" + userId)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("Unable to find user " + userId + ".")
                    return
                }
            }
            toast.error("Internal error.")
        })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        createUser()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/user")
    }
    
    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <FormButton label="Email address" id="email" required="required" value={email} setValue={setEmail} icon="fa-solid fa-envelope" />
                            <FormButton label="Name" id="name" required="required" value={name} setValue={setName} icon="fa-solid fa-input-text" />
                            <FormButton label="Password" id="password" required="required" value={password} setValue={setPassword} icon="fa-solid fa-lock" type="password" minLength={8} />
                        </div>
                    </div>

                    <div className="card-body">
                        <Permissions data={userPermissions} setData={setUserPermissions} />
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Create user</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default UserCreate
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from 'react-select'
import { toast } from "react-toastify"
import FormButton from "../../components/FormButton"
import { getId, getToken, usePermissions } from "../../user/User"

const ProspectCreate = () => {
    const navigate = useNavigate()
    const options = []

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.prospect !== undefined && !permissions.prospect) {
            toast.error("You don't have permission to create prospects!")
            navigate("/")
            return
        }
        
        axios.get(process.env.REACT_APP_API_URL + "/api/campaigns", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            res.data.forEach((campaign, _) => {
                options.push({
                    value: campaign.id,
                    label: campaign.name
                })
            })
        }).catch(error => {
            console.log(error)
            toast.error("Unable to load campaigns")
        })
    }, [permissions])

    const [campaignId, setCampaignId] = useState(0)
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [street, setStreet] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [city, setCity] = useState("")
    const [birthday, setBirthday] = useState("")
    const [meetingTime, setMeetingTime] = useState("")
    const [meetingLocation, setMeetingLocation] = useState("teams")
    const [wantToSell, setWantToSell] = useState(false)
    const [wantToSellWithinAYear, setWantToSellWithinAYear] = useState(false)

    async function createProspect() {
        if(phone === "" && mobile === "") {
            toast.error("Phone or mobile number must be set.")
            return
        }

        axios.post(process.env.REACT_APP_API_URL + "/api/prospects", JSON.stringify({
            "campaign_id": campaignId,
            "name": name,
            "phone": phone,
            "mobile": mobile,
            "email_address": emailAddress,
            "street": street,
            "postal_code": postalCode,
            "city": city,
            "birthday": birthday === "" ? 0 : Math.floor(new Date(birthday).getTime() / 1000),
            "meeting_time": meetingTime === "" ? 0 : Math.floor(new Date(meetingTime).getTime() / 1000),
            "meeting_location": meetingLocation,
            "want_to_sell": wantToSell,
            "want_to_sell_within_a_year": wantToSellWithinAYear
        }), { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(res => {
            toast.success("Created prospect " + name + " (#" + res.data.id + ").")
            navigate("/prospect/" + res.data.id)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 409) {
                    toast.error("There is a prospect with the same phone and mobile number.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        createProspect()
    }

    const handleBack = async e => {
        e.preventDefault()
        navigate("/prospect")
    }

    function onMeetingLocationChanged(event) {
        setMeetingLocation(event.target.id)
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-lg-3 col-12">
                                    <b>Campaign</b>
                                    <Select options={options} onChange={(choice) => setCampaignId(choice.value)} />
                                    <br />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Name" id="name" value={name} setValue={setName} icon="fa-solid fa-input-text" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Birthday" id="birthday" type="date" value={birthday} setValue={setBirthday} icon="fa-solid fa-cake-candles" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <FormButton label="Phone" id="phone" type="tel" value={phone} setValue={setPhone} icon="fa-solid fa-phone" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <FormButton label="Mobile" id="mobile" type="tel" value={mobile} setValue={setMobile} icon="fa-solid fa-mobile" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <FormButton label="Email address" id="emailAddress" type="email" value={emailAddress} setValue={setEmailAddress} icon="fa-solid fa-envelope" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <FormButton label="Street" id="street" value={street} setValue={setStreet} icon="fa-solid fa-road" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <FormButton label="Postal code" id="postalCode" value={postalCode} setValue={setPostalCode} icon="fa-solid fa-mailbox" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                                <div className="col-lg-4 col-12">
                                    <FormButton label="City" id="city" value={city} setValue={setCity} icon="fa-solid fa-city" labelSize="col-lg-3" size="col-lg-7" />
                                </div>
                            </div>

                            <p><b>Meeting</b></p>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <FormButton label="Time" id="meetingTime" type="datetime-local" value={meetingTime} setValue={setMeetingTime} icon="fa-solid fa-clock" />
                                </div>
                                <div className="col-lg-6 col-12" onChange={onMeetingLocationChanged}>
                                    <p><b>Location</b></p>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="meetingLocation" id="teams" defaultChecked />
                                        <label className="form-check-label" htmlFor="teams">
                                            Teams
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="meetingLocation" id="house" />
                                        <label className="form-check-label" htmlFor="house">
                                            House
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="icheck-primary">
                                <input type="checkbox" id="wantToSell" value={wantToSell} onChange={e => setWantToSell(e.target.checked)} />
                                <label htmlFor="wantToSell">
                                    Want to sell
                                </label>
                            </div>
                            <div className="icheck-primary">
                                <input type="checkbox" id="wantToSellWithinAYear" value={wantToSellWithinAYear} onChange={e => setWantToSellWithinAYear(e.target.checked)} />
                                <label htmlFor="wantToSellWithinAYear">
                                    Want to sell within a year
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        <button type="submit" className="btn btn-success">Create prospect</button>
                    </div>
                </div>
            </form>
        </>
    )
}
   
export default ProspectCreate
import axios from "axios"
import { sha512 } from "crypto-hash"
import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { isLoggedIn } from "../user/User"
import FormButton from "../components/FormButton"

const ResetPassword = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    let token = searchParams.get("token")
    let email = searchParams.get("email")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (isLoggedIn()) {
            navigate("/")
        }
    })

    async function resetPassword(navigate) {
        setIsLoading(true)
        var passwordHash = await sha512(password)
        axios.post(process.env.REACT_APP_API_URL + "/reset/" + token, JSON.stringify({
            "email": email,
            "password": passwordHash
        })).then(_ => {
            toast.success("Password successfully saved.")
            navigate("/login")
        }).catch(error => {
            if (error.response) {
                setIsLoading(false)
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 404) {
                    toast.error("There is no user with this email address or a password reset request.")
                    return
                } else if(statusCode === 406) {
                    toast.error("Invalid token.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        resetPassword(navigate)
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <h1>{process.env.REACT_APP_APP_NAME}</h1>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Insert your new password</p>
                        <form onSubmit={handleSubmit}>
                            <FormButton placeholder="Passwort" id="password" required="required" value={password} setValue={setPassword} icon="fas fa-lock" type="password" minLength={8} size="mb-3" />
                            <div className="form-group row">
                                {
                                    isLoading ? 
                                    <button type="submit" className="btn btn-primary btn-block" disabled>Saving password...</button>
                                    :
                                    <button type="submit" className="btn btn-primary btn-block">Save password</button>
                                }
                            </div>
                        </form>

                        <p className="mt-3 mb-1">
                            <Link to="/login">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { getToken, useCurrentUser } from "../user/User"

const CampaignSelection = () => {
    const { email } = useCurrentUser()
    const [campaignList, setCampaignList] = useState("There are no campaigns")

    if(email != null) {
      useEffect(() => {
        const abortCont = new AbortController()
  
        axios.get(process.env.REACT_APP_API_URL + "/api/campaigns", {
            signal: abortCont.signal,
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        }).then(res => {
          if(res.data === null || res.data === "") {
            return
          }
          setCampaignList(res.data.map((campaign) =>  <li key={campaign.id}><Link to={"/campaign/call/" + campaign.id}>{campaign.name}</Link></li>))
        }).catch(err => {
          if(err.message === "canceled") {
            return
          }
          console.log(err)
          toast.error("Could not load campaigns.")
        })
      }, [email])
    }


    return <>
        <p>Select a campaign to start calling:</p>
        <ul>{campaignList}</ul>
    </>
}

export default CampaignSelection
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { isLoggedIn } from "../user/User"
import { toast } from 'react-toastify'
import FormButton from '../components/FormButton'

const Login2FA = () => {
    const location = useLocation()
    const email = location.state.email
    const passwordHash = location.state.passwordHash
    const remember = location.state.remember
    const [otp, setOTP] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    if (isLoggedIn()) {
        navigate("/")
        return
    }
    
    async function loginUser(email, passwordHash, remember, otp, navigate) {
        setIsLoading(true)

        axios.post(process.env.REACT_APP_API_URL + "/login", JSON.stringify({
            "email": email,
            "password": passwordHash,
            "otp": otp
        })).then(res => {
            localStorage.clear()
            sessionStorage.clear()
            if(remember) {
                localStorage.setItem('token', res.data)
            } else {
                sessionStorage.setItem('token', res.data)
            }
            toast.success("Successfully logged in.")
            navigate("/")
        }).catch(error => {
            setIsLoading(false)
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                } else if(statusCode === 401) {
                    toast.error("Invalid one time password. Please try again.")
                    return
                }
            }
            toast.error("Internal error.")
        })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        loginUser(email, passwordHash, remember, otp, navigate)
    }

    return (
        <div className="login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <h1>{process.env.REACT_APP_APP_NAME}</h1>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Please insert your one time password.</p>

                        <form onSubmit={handleSubmit}>
                            <FormButton id="otp" required="required" value={otp} setValue={setOTP} icon="fas fa-hashtag" type="text" placeholder="One time password" size="mb-3" />

                            <div className="form-group row">
                                <div className="col-12">
                                    {
                                        isLoading ?
                                        <button type="submit" className="btn btn-primary btn-block" disabled>Logging in...</button>
                                        :
                                        <button type="submit" className="btn btn-primary btn-block">Login</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default Login2FA
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useProspect } from "../../prospect/Prospect"
import ProspectActionTimeline from "./ProspectActionTimeline"
import ProspectDetailView from './ProspectDetailView'
import { useState } from 'react'

const ProspectEdit = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    const [notes, setNotes] = useState("")
    const [callAt, setCallAt] = useState("")
    let { campaignId, setCampaignId, campaignName, name, setName, phone, setPhone, mobile, setMobile, emailAddress, setEmailAddress,
        street, setStreet, postalCode, setPostalCode, city, setCity, birthday, setBirthday, meetingTime, setMeetingTime, meetingLocation, setMeetingLocation,
        wantToSell, setWantToSell, wantToSellWithinAYear, setWantToSellWithinAYear, prospectActions, canSendMailToFirm, canSendMailToProspect } = useProspect(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        let campaignPermission = "campaign_" + campaignName
        if(permissions[campaignPermission] !== undefined && !permissions[campaignPermission]) {
            toast.error("You don't have permission to edit prospects of campaign " + campaignName + "!")
            navigate("/")
        }
    }, [permissions])

    const handleBack = async e => {
        e.preventDefault()
        navigate("/prospect")
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <div className="row">
                <div className="col-12 col-lg-10">
                    <ProspectDetailView id={id} name={name} setName={setName} birthday={birthday} setBirthday={setBirthday} phone={phone} setPhone={setPhone} 
                        mobile={mobile} setMobile={setMobile} emailAddress={emailAddress} setEmailAddress={setEmailAddress} street={street} setStreet={setStreet}
                        postalCode={postalCode} setPostalCode={setPostalCode} city={city} setCity={setCity} meetingTime={meetingTime} setMeetingTime={setMeetingTime}
                        meetingLocation={meetingLocation} setMeetingLocation={setMeetingLocation} wantToSell={wantToSell} setWantToSell={setWantToSell}
                        wantToSellWithinAYear={wantToSellWithinAYear} setWantToSellWithinAYear={setWantToSellWithinAYear} notes={notes} setNotes={setNotes}
                        callAt={callAt} setCallAt={setCallAt} campaignId={campaignId} setCampaignId={setCampaignId} returnTo="/prospect"
                        canSendMailToFirm={canSendMailToFirm} canSendMailToProspect={canSendMailToProspect} />
                </div>

                <div className="col-12 col-lg-2">
                    <ProspectActionTimeline prospectActions={prospectActions}/>
                </div>
            </div>
        </>
    )
}

export default ProspectEdit
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Login from './pages/Login'
import Login2FA from './pages/Login2FA'
import RequestPasswordReset from './pages/RequestPasswordReset'
import ResetPassword from './pages/ResetPassword'
import Wrapper from './components/Wrapper'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login2fa" element={<Login2FA />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path='*' element={<Wrapper />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position='bottom-right' />
    </>
  )
}

export default App
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getId, getToken, usePermissions } from "../../user/User"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { toast } from "react-toastify"

const UserList = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        if(permissions.user !== undefined && !permissions.user) {
            toast.error("You don't have permission to see users!")
            navigate("/")
        }
    }, [permissions])

    const handleCreate = async e => {
        e.preventDefault(),
        navigate("/user/create")
    }

    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Email address",
        accessor: (row, _) => {
            return <a href={"mailto:" + row.email}>{row.email}</a>
        }
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Edit",
        UrlPart: "user"
    }]

    const [users, setUsers] = useState([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + "/api/users", {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(res => {
            if(res.data === null || res.data === "") {
                return
            }
            setUsers(res.data)
        }).catch(_ => {
            toast.error("Unable to load users!")
        })
    }, [])

    return (
        <div>
            <button className="btn btn-primary" onClick={handleCreate}>Create user</button>

            <BasicTableComponent columns={columns} data={users} />
        </div>
    )
}

export default UserList
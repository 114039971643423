import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { getId, getToken, useSettings } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"

const Settings = () => {
    const navigate = useNavigate()

    let { twoFactorAuthenticationEnabled: twoFactorAuthenticationEnabledSetting } = useSettings(getId())
    const [ twoFactorAuthenticationEnabled, setTwoFactorAuthenticationEnabled ] = useState(false)

    useEffect(() => {
        setTwoFactorAuthenticationEnabled(twoFactorAuthenticationEnabledSetting)
    }, [twoFactorAuthenticationEnabledSetting])

    async function enableTwoFactorAuthentication() {
        navigate("/settings/enable2fa")
    }

    async function disableTwoFactorAuthentication() {
        axios.get(process.env.REACT_APP_API_URL + "/api/settings/2fa/disable", { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            toast.success("Disabled two factor authentication")
            setTwoFactorAuthenticationEnabled(false)
        }).catch(error => {
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    return (
        <>
            <form className="form-horizontal">
                <div className="card card-primary">
                    <div className="card-body">
                        <div className="col-12">
                            <p>
                                Two-factor authentication&nbsp;
                                <button type="button" className="btn btn-success" disabled={twoFactorAuthenticationEnabled} onClick={enableTwoFactorAuthentication}>Enable</button>&nbsp;
                                <button type="button" className="btn btn-danger" disabled={!twoFactorAuthenticationEnabled} onClick={disableTwoFactorAuthentication}>Disable</button>
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Settings
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useCampaignProspects } from "../../prospect/Prospect"
import ProspectActionTimeline from "../prospect/ProspectActionTimeline"
import ProspectDetailView from "../prospect/ProspectDetailView"

const CampaignCall = () => {
    const navigate = useNavigate()

    let { param } = useParams()
    const [campaignId, setCampaignId] = useState(param)
    let { hasProspect, prospectId, campaignName, name, setName, phone, setPhone, mobile, setMobile, emailAddress, setEmailAddress,
        street, setStreet, postalCode, setPostalCode, city, setCity, birthday, setBirthday, meetingTime, setMeetingTime, meetingLocation, setMeetingLocation,
        wantToSell, setWantToSell, wantToSellWithinAYear, setWantToSellWithinAYear, prospectActions, canSendMailToFirm, canSendMailToProspect } = useCampaignProspects(campaignId)
    const [notes, setNotes] = useState("")
    const [callAt, setCallAt] = useState("")

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        let campaignPermission = "campaign_" + campaignName
        if(permissions[campaignPermission] !== undefined && !permissions[campaignPermission]) {
            toast.error("You don't have permission to call campaign " + campaignName + "!")
            navigate("/")
        }
    }, [permissions, campaignName])

    useEffect(() => {
        if(hasProspect === false) {
            navigate("/")
            toast.info("There are no more prospects to call for this campaign.")
        }
    }, [hasProspect])

    const handleBack = async e => {
        e.preventDefault()
        axios.get(process.env.REACT_APP_API_URL + "/api/prospects/free/" + prospectId, { headers: {
            "Authorization": "Bearer " + getToken()
        }}).then(_ => {
            navigate("/")
        }).catch(error => {
            navigate("/")
            if (error.response) {
                var statusCode = error.response.status
                if(statusCode === 400) {
                    toast.error("Invalid request.")
                    return
                }
            }
            toast.error("Internal error")
        })
    }

    return (
        <>
            <button className="btn btn-primary back" onClick={handleBack}>Back to overview</button><br /><br />

            <div className="row">
                <div className="col-12 col-lg-10">
                    <ProspectDetailView id={prospectId} name={name} setName={setName} birthday={birthday} setBirthday={setBirthday} phone={phone} setPhone={setPhone} 
                        mobile={mobile} setMobile={setMobile} emailAddress={emailAddress} setEmailAddress={setEmailAddress} street={street} setStreet={setStreet}
                        postalCode={postalCode} setPostalCode={setPostalCode} city={city} setCity={setCity} meetingTime={meetingTime} setMeetingTime={setMeetingTime}
                        meetingLocation={meetingLocation} setMeetingLocation={setMeetingLocation} wantToSell={wantToSell} setWantToSell={setWantToSell}
                        wantToSellWithinAYear={wantToSellWithinAYear} setWantToSellWithinAYear={setWantToSellWithinAYear} notes={notes} setNotes={setNotes}
                        callAt={callAt} setCallAt={setCallAt} campaignId={campaignId} setCampaignId={setCampaignId} returnTo="reload"
                        canSendMailToFirm={canSendMailToFirm} canSendMailToProspect={canSendMailToProspect} />
                </div>

                <div className="col-12 col-lg-2">
                    <ProspectActionTimeline prospectActions={prospectActions}/>
                </div>
            </div>
        </>
    )
}

export default CampaignCall
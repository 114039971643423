import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getToken } from '../user/User'

export const useProspect = (id) => {
    const [campaignId, setCampaignId] = useState(0)
    const [campaignName, setCampaignName] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [street, setStreet] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [city, setCity] = useState("")
    const [birthday, setBirthday] = useState("")
    const [meetingTime, setMeetingTime] = useState("")
    const [meetingLocation, setMeetingLocation] = useState("")
    const [wantToSell, setWantToSell] = useState(false)
    const [wantToSellWithinAYear, setWantToSellWithinAYear] = useState(false)
    const [prospectActions, setProspectActions] = useState([])
    const [canSendMailToFirm, setCanSendMailToFirm] = useState(false)
    const [canSendMailToProspect, setCanSendMailToProspect] = useState(false)

    useEffect(() => {
        if(id !== null && id !== undefined) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/prospects/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            })
            .then(res => {
                var data = res.data

                setCampaignId(data.campaign_id)
                setCampaignName(data.campaign_name)
                setName(data.name)
                setPhone(data.phone)
                setMobile(data.mobile)
                setEmailAddress(data.email_address)
                setStreet(data.street)
                setPostalCode(data.postal_code)
                setCity(data.city)
                if(data.birthday != 0) {
                    var date = new Date(data.birthday * 1000)
                    var localDate = date.toLocaleDateString()
                    var dateParts
                    if(localDate.includes(".")) {
                        dateParts = localDate.split(".")
                    } else if(localDate.includes("/")) {
                        dateParts = localDate.split("/")
                    }
                    if(dateParts.length >= 3) {
                        var dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                        setBirthday(dateString)
                    }
                }
                if(data.meeting_time != 0) {
                    var date = new Date(data.meeting_time * 1000)
                    var localDate = date.toLocaleDateString()
                    var dateParts
                    if(localDate.includes(".")) {
                        dateParts = localDate.split(".")
                    } else if(localDate.includes("/")) {
                        dateParts = localDate.split("/")
                    }
                    if(dateParts.length >= 3) {
                        var dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                        setMeetingTime(dateString + "T" + date.toLocaleTimeString().substring(0, 5))
                    }
                }
                setMeetingLocation(data.meeting_location)
                setWantToSell(data.want_to_sell)
                setWantToSellWithinAYear(data.want_to_sell_within_a_year)
                if(data.actions != null) {
                    setProspectActions(data.actions)
                }
                setCanSendMailToFirm(data.can_send_mail_to_firm)
                setCanSendMailToProspect(data.can_send_mail_to_prospect)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { id, campaignId, setCampaignId, campaignName, setCampaignName, name, setName, phone, setPhone, mobile, setMobile, emailAddress, setEmailAddress, street, setStreet,
        postalCode, setPostalCode, city, setCity, birthday, setBirthday, meetingTime, setMeetingTime, meetingLocation, setMeetingLocation, wantToSell, setWantToSell,
        wantToSellWithinAYear, setWantToSellWithinAYear, prospectActions, canSendMailToFirm, canSendMailToProspect }
}

export const useCampaignProspects = (campaignId) => {
    const [hasProspect, setHasProspect] = useState(true)
    const [prospectId, setProspectId] = useState(0)
    const [campaignName, setCampaignName] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [street, setStreet] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [city, setCity] = useState("")
    const [meetingTime, setMeetingTime] = useState("")
    const [birthday, setBirthday] = useState("")
    const [meetingLocation, setMeetingLocation] = useState("")
    const [wantToSell, setWantToSell] = useState(false)
    const [wantToSellWithinAYear, setWantToSellWithinAYear] = useState(false)
    const [prospectActions, setProspectActions] = useState([])
    const [canSendMailToFirm, setCanSendMailToFirm] = useState(false)
    const [canSendMailToProspect, setCanSendMailToProspect] = useState(false)

    useEffect(() => {
        if(campaignId !== null && campaignId !== undefined) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/campaigns/call/" + campaignId, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            }).then(res => {
                var data = res.data

                setProspectId(data.id)
                setCampaignName(data.campaign_name)
                setName(data.name)
                setPhone(data.phone)
                setMobile(data.mobile)
                setEmailAddress(data.email_address)
                setStreet(data.street)
                setPostalCode(data.postal_code)
                setCity(data.city)
                if(data.birthday != 0) {
                    var date = new Date(data.birthday * 1000)
                    var localDate = date.toLocaleDateString()
                    console.log(localDate)
                    var dateParts
                    var dateString
                    if(localDate.includes(".")) {
                        //dd.mm.yyyy
                        dateParts = localDate.split(".")
                        dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                    } else if(localDate.includes("/")) {
                        //dd/mm/yyyy
                        dateParts = localDate.split("/")
                        dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                    } else if(localDate.includes("-")) {
                        //yyyy-mm-dd 1987-05-17
                        dateParts = localDate.split("-")
                        dateString = dateParts[0] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[2].length == 1 ? "0" + dateParts[2] : dateParts[2])
                    }
                    if(dateString != undefined) {
                        setBirthday(dateString)
                    }
                }
                if(data.meeting_time != 0) {
                    var date = new Date(data.meeting_time * 1000)
                    var localDate = date.toLocaleDateString()
                    var dateParts
                    if(localDate.includes(".")) {
                        dateParts = localDate.split(".")
                    } else if(localDate.includes("/")) {
                        dateParts = localDate.split("/")
                    }
                    if(dateParts.length >= 3) {
                        var dateString = dateParts[2] + "-" + (dateParts[1].length == 1 ? "0" + dateParts[1] : dateParts[1]) + "-" + (dateParts[0].length == 1 ? "0" + dateParts[0] : dateParts[0])
                        setMeetingTime(dateString + "T" + date.toLocaleTimeString().substring(0, 5))
                    }
                }
                setMeetingLocation(data.meeting_location)
                setWantToSell(data.want_to_sell)
                setWantToSellWithinAYear(data.want_to_sell_within_a_year)
                if(data.actions != null) {
                    setProspectActions(data.actions)
                }
                setCanSendMailToFirm(data.can_send_mail_to_firm)
                setCanSendMailToProspect(data.can_send_mail_to_prospect)
            }).catch(err => {
                if(err.message === "canceled") {
                    return
                }
                console.log(err)
                if (err.response) {
                    var statusCode = err.response.status
                    if(statusCode === 404) {
                        setHasProspect(false)
                        return
                    }
                }
                toast.error("Internal error")
            })
        
            return () => abortCont.abort()
        }
    }, [campaignId])

    return { campaignId, hasProspect, setHasProspect, prospectId, setProspectId, campaignName, setCampaignName, name, setName, phone, setPhone,
        mobile, setMobile, emailAddress, setEmailAddress, street, setStreet, postalCode, setPostalCode, city, setCity, birthday, setBirthday, meetingTime, setMeetingTime,
        meetingLocation, setMeetingLocation, wantToSell, setWantToSell, wantToSellWithinAYear, setWantToSellWithinAYear, prospectActions,
        canSendMailToFirm, canSendMailToProspect }
}
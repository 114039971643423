import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router"
import { getId, getToken, usePermissions } from "../../user/User"
import { toast } from "react-toastify"
import { useEffect } from "react"
import { useProspect } from "../../prospect/Prospect"
import axios from 'axios'

const ProspectRemoveCallback = () => {
    const navigate = useNavigate()

    let { id } = useParams()
    let { campaignName, name } = useProspect(id)

    const { permissions } = usePermissions(getId())
    useEffect(() => {
        let campaignPermission = "campaign_" + campaignName
        if(permissions[campaignPermission] !== undefined && !permissions[campaignPermission]) {
            toast.error("You don't have permission to edit prospects of campaign " + campaignName + "!")
            navigate("/")
        }
    }, [permissions])

    useEffect(() => {
        if(name == "") {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + "/api/removecallback/" + id, {
            headers: {
                "Authorization": "Bearer " + getToken()
            }
        })
        .then(_ => {
            toast.success("Removed callback from " + name + "!")
            navigate("/prospect/callback")
        }).catch(err => {
            console.log(err)
            toast.error("Unable to remove callback!")
            navigate("/prospect/callback")
        })
    }, [name])

    return (
        <>
            <p>Redirecting...</p>
        </>
    )
}

export default ProspectRemoveCallback
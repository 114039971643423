import CampaignSelection from "../campaign/CampaignSelection"

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="row">
        <CampaignSelection />
      </div>
    </div>
  )
}

export default Dashboard
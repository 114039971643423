import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <div className="not-found">
      <p>Page not found. <Link to="/">Back to homepage.</Link></p>
    </div>
  )
}
 
export default NotFound
import React from "react"
import { Link } from "react-router-dom"
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from 'react-table'

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="input-group mb-3">
            <label htmlFor="globalsearch" className="col-sm-4 col-form-label">Global search</label>
            <input
                id="globalsearch"
                style={{maxWidth: "400px"}}
                className="form-control"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder="Search"
            />
            <div className="input-group-append">
                <div className="input-group-text">
                    <span className="fa-solid fa-magnifying-glass"></span>
                </div>
            </div>
        </div>
    )
}

function DefaultColumnFilter({
    column: { filterValue, setFilter },
}) {
    return (
        <div className="input-group">
            <input
                className="form-control"
                value={filterValue || ""}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search`}
            />
            <div className="input-group-append">
                <div className="input-group-text">
                    <span className="fa-solid fa-magnifying-glass"></span>
                </div>
            </div>
        </div>
    )
}

export function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])
  
    return (
      <select
        value={filterValue}
        onChange={e => {
          setFilter(e.target.value || undefined)
        }}
        className="form-control"
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    )
}

export function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])
  
    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <input
          value={filterValue[0] || ''}
          type="number"
          min={min}
          max={max}
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
          }}
          placeholder={`Min (${min})`}
          style={{
            width: '100px',
            marginRight: '0.5rem',
          }}
          className="form-control"
        />
        to
        <input
          value={filterValue[1] || ''}
          type="number"
          min={min}
          max={max}
          onChange={e => {
            const val = e.target.value
            setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
          }}
          placeholder={`Max (${max})`}
          style={{
            width: '100px',
            marginLeft: '0.5rem',
          }}
          className="form-control"
        />
      </div>
    )
}

function BasicTableComponent({ columns, data }) {
    const defaultColumn = React.useMemo(() => ({
        Filter: DefaultColumnFilter,
    }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    return (
        <div>
            <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            <table className="table table-bordered" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header') + " "}
                                    {column.canSort ?
                                    <span {...column.getSortByToggleProps()}>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fa-solid fa-sort-down"></i>
                                                : <i className="fa-solid fa-sort-up"></i>
                                            : <i className="fa-solid fa-sort"></i>}
                                    </span> : null}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, _) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    if (cell.column.UrlPart != null) {
                                        return <td {...cell.getCellProps()}>
                                            <Link to={"/" + cell.column.UrlPart + "/" + (cell.row.values.uuid === undefined ? cell.row.values.id : cell.row.values.uuid)}>{cell.column.Header}</Link>
                                        </td>
                                    } else {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <ul className="pagination">
                <li className={"page-item " + (canPreviousPage ? null : "disabled")} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <p className="page-link">First</p>
                </li>
                <li className={"page-item " + (canPreviousPage ? null : "disabled")} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <p className="page-link">{'<'}</p>
                </li>
                <li className={"page-item " + (canNextPage ? null : "disabled")} onClick={() => nextPage()} disabled={!canNextPage}>
                    <p className="page-link">{'>'}</p>
                </li>
                <li className={"page-item " + (canNextPage ? null : "disabled")} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <p className="page-link">Last</p>
                </li>
                <li>
                    <p className="page-link">
                        Page{' '}
                        <strong>
                            {pageIndex + 1} from {pageOptions.length}
                        </strong>{' '}
                    </p>
                </li>
                <li>
                    <p className="page-link">
                        <input
                            className="form-control"
                            type="number"
                            min="1"
                            max={pageCount}
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(page)
                            }}
                            style={{ width: '70px', height: '20px' }}
                        />
                    </p>
                </li>
            </ul>
            <ul className="pagination">
                <select
                    className="form-control"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    style={{ width: '110px', height: '38px' }}
                >
                    {[5, 10, 25, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
                <li>
                    <p className="page-link">
                        of {rows.length} entries
                    </p>
                </li>
            </ul>
        </div>
    )
}

export default BasicTableComponent
import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Header from './Header'
import Dashboard from '../pages/Dashboard'
import UserList from '../pages/user/UserList'
import UserEdit from '../pages/user/UserEdit'
import UserCreate from '../pages/user/UserCreate'
import NotFound from '../pages/404'
import Footer from './Footer'
import { isLoggedIn } from "../user/User"
import Settings from '../pages/settings/Settings'
import Enable2FA from '../pages/settings/Enable2FA'
import CampaignList from '../pages/campaign/CampaignList'
import CampaignCreate from '../pages/campaign/CampaignCreate'
import CampaignEdit from '../pages/campaign/CampaignEdit'
import ProspectList from '../pages/prospect/ProspectList'
import ProspectCreate from '../pages/prospect/ProspectCreate'
import ProspectEdit from '../pages/prospect/ProspectEdit'
import CampaignCall from '../pages/campaign/CampaignCall'
import CallbackProspectList from '../pages/prospect/CallbackProspectList'
import ProspectRemoveCallback from '../pages/prospect/ProspectRemoveCallback'

function Wrapper() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login")
        }
    })

    return (
        <div className="wrapper">
            <Navbar />

            <Sidebar />

            <div className="content-wrapper">
                <Header />

                <section className="content">
                    <div className="container-fluid">
                        <Routes>
                            <Route exact path="/" element={<Dashboard />} />
                            <Route exact path="/user" element={<UserList />} />
                            <Route exact path="/user/create" element={<UserCreate />} />
                            <Route path="/user/:id" element={<UserEdit />} />
                            <Route exact path="/settings" element={<Settings />} />
                            <Route exact path="/settings/enable2fa" element={<Enable2FA />} />
                            <Route exact path="/campaign" element={<CampaignList />} />
                            <Route exact path="/campaign/create" element={<CampaignCreate />} />
                            <Route path="/campaign/call/:param" element={<CampaignCall />} />
                            <Route path="/campaign/:id" element={<CampaignEdit />} />
                            <Route exact path="/prospect" element={<ProspectList />} />
                            <Route exact path="/prospect/callback" element={<CallbackProspectList />} />
                            <Route exact path="/prospect/create" element={<ProspectCreate />} />
                            <Route path="/prospect/:id" element={<ProspectEdit />} />
                            <Route path="/removecallback/:id" element={<ProspectRemoveCallback />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    )
}

export default Wrapper
